/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .event-enter {
    @apply opacity-0 transform -translate-y-full;
  }
  .event-enter-active {
    @apply opacity-100 transform translate-y-0;
    @apply transition-all duration-500 ease-in-out;
  }
  .event-exit {
    @apply opacity-100 transform translate-y-0;
  }
  .event-exit-active {
    @apply opacity-0 transform translate-y-full;
    @apply transition-all duration-500 ease-in-out;
  }
}